<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-3">
        <input-select-search
          ref="anoLetivoId"
          :label="$t(`ACADEMICO.ANO_LETIVO`)"
          :options="opcoes.anosLetivos"
          v-model="filtro.anoLetivoId"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          ref="segmentacaoId"
          :label="$t(`ACADEMICO.SEGMENTACAO`)"
          :options="opcoes.segmentacoes"
          v-model="filtro.segmentacaoId"
          :isDisabled="desabilitar.segmentacao"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
          v-model="filtro.cursoId"
          :isDisabled="desabilitar.curso"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          ref="turmaId"
          :label="$t(`ACADEMICO.TURMA`)"
          :options="opcoes.turmas"
          v-model="filtro.turmaId"
          :isDisabled="desabilitar.turma"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-select-search
          ref="disciplinaId"
          :label="$t(`ACADEMICO.DISCIPLINA`)"
          :options="opcoes.disciplinas"
          v-model="filtro.disciplinaId"
          :isDisabled="desabilitar.disciplina"
          required
        />
      </div>
      <div class="col-12 col-md-6">
        <input-select-search
          ref="avaliacaoId"
          :label="$t(`ACADEMICO.AVALIACAO`)"
          :options="opcoes.avaliacoes"
          v-model="filtro.avaliacaoId"
          :isDisabled="desabilitar.avaliacao"
          required
        />
      </div>
      <div class="col-12 col-md-auto align-items-center">
        <b-button
          variant="primary"
          class="botao-acao-filtro w-100"
          @click="filtrar"
        >
          {{ $t('GERAL.FILTRAR') }}
        </b-button>
      </div>
      <div class="col-12 col-md-auto">
        <b-button
          variant="secondary"
          class="botao-acao-filtro mt-3 w-100"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import DisciplinasService from '@/common/services/academico/disciplinas.service';
import AvaliacoesService from '@/common/services/academico/avaliacoes.service';

import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        anoLetivoId: '',
        segmentacaoId: '',
        cursoId: '',
        turmaId: '',
        disciplinaId: '',
        avaliacaoId: '',
      },
      desabilitar: {
        turma: true,
        disciplina: true,
        segmentacao: true,
        avaliacao: true,
        curso: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        segmentacoes: [],
        turmas: [],
        disciplinas: [],
        avaliacoes: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
  },
  watch: {
    'filtro.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getCursos(anoLetivoId);
        this.getSegmentacoes();
      } else {
        this.opcoes.cursos = [];
        this.opcoes.turmas = [];
        this.opcoes.segmentacoes = [];
        this.opcoes.disciplinas = [];
        this.opcoes.avaliacoes = [];
        this.desabilitar.curso = true;
        this.desabilitar.turma = true;
        this.desabilitar.segmentacao = true;
        this.desabilitar.avaliacao = true;
        this.desabilitar.disciplina = true;
      }
      this.filtro.cursoId = null;
      this.filtro.turmaId = null;
      this.filtro.segmentacaoId = null;
      this.filtro.avaliacaoId = null;
      this.filtro.disciplinaId = null;
    },
    'filtro.cursoId': {
      handler(novoCursoId) {
        if (novoCursoId) {
          this.getTurmas();
        } else {
          this.desabilitar.turma = true;
          this.desabilitar.disciplina = true;
          this.desabilitar.avaliacao = true;
          this.opcoes.turmas = [];
          this.opcoes.disciplinas = [];
          this.opcoes.avaliacoes = [];
        }
        this.filtro.turmaId = null;
        this.filtro.disciplinaId = null;
        this.filtro.avaliacaoId = null;
      },
    },
    'filtro.turmaId': {
      handler(turmaId) {
        if (turmaId) {
          this.getDisciplinas();
        } else {
          this.desabilitar.disciplina = true;
          this.desabilitar.avaliacao = true;
          this.opcoes.disciplinas = [];
          this.opcoes.avaliacoes = [];
        }
        this.filtro.disciplinaId = null;
        this.filtro.avaliacaoId = null;
      },
    },
    'filtro.disciplinaId': {
      handler(disciplinaId) {
        if (disciplinaId) {
          this.getAvaliacaoes();
        } else {
          this.desabilitar.avaliacao = true;
          this.opcoes.avaliacoes = [];
        }
        this.filtro.avaliacaoId = null;
      },
    },
    filtro: {
      handler(filtro) {
        this.$emit('atualizar-filtro', filtro);
      },
      deep: true,
    },
  },
  methods: {
    async getAnosLetivo() {
      try {
        this.$store.dispatch(START_LOADING);
        const { data } = await AnoLetivoService.listar(this.paginacao);

        data.forEach((element) => {
          element.text = element.ano;
          element.value = element.id;
        });

        this.opcoes.anosLetivos = data;
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    getSegmentacoes() {
      this.opcoes.anosLetivos.forEach((element) => {
        if (element.id == this.filtro.anoLetivoId) {
          this.opcoes.segmentacoes = element.segmentacoes;
          this.opcoes.segmentacoes.forEach((segmentacao) => {
            segmentacao.text = segmentacao.descricao;
            segmentacao.value = segmentacao.id;
          });
          this.desabilitar.segmentacao = false;
        }
      });
    },

    async getCursos(anoLetivoId) {
      try {
        this.$store.dispatch(START_LOADING);
        const { data } = await CursoService.obterPorAnoLetivo(anoLetivoId);

        this.opcoes.cursos = data.map((row) => ({
          ...row,
          text: row.descricao,
          value: row.id,
        }));
        this.desabilitar.curso = false;
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    async getTurmas() {
      try {
        this.$store.dispatch(START_LOADING);
        const { data } = await TurmaService.buscarPorCursoId(
          this.filtro.cursoId
        );

        this.opcoes.turmas = data.map((row) => ({
          ...row,
          text: row.descricao,
          value: row.id,
        }));

        this.habilitarCampo('turma');
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    async getDisciplinas() {
      try {
        this.$store.dispatch(START_LOADING);
        const { data } = await DisciplinasService.buscarPorTurmaId(
          this.filtro.turmaId
        );

        this.opcoes.disciplinas = data.map((row) => ({
          ...row,
          text: row.descricao,
          value: row.id,
        }));

        this.habilitarCampo('disciplina');
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    async getAvaliacaoes() {
      try {
        this.$store.dispatch(START_LOADING);
        const {
          data: { itens },
        } = await AvaliacoesService.obterPorFiltro(this.filtro);
        this.opcoes.avaliacoes = itens.map((row) => ({
          ...row,
          text: row.descricao,
          value: row.id,
        }));
        this.habilitarCampo('avaliacao');
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar');
    },
    limparFiltro() {
      this.filtro = {
        cursoId: '',
        segmentacaoId: '',
        turmaId: '',
        disciplinaId: '',
        avaliacaoId: '',
      };
      (this.desabilitar = {
        turma: true,
        disciplina: true,
        segmentacao: true,
        avaliacao: true,
      }),
        this.$emit('limpar-filtro');
    },
  },
};
</script>
