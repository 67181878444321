<template>
  <b-table
    class="mt-4"
    :fields="tabela.fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    responsive
    show-empty
    stacked="md"
    striped
    hover
  >
    <template #cell(valorAvaliacao)="item">
      <template>
        <div>
          <b-input-group class="mt-3">
            <div class="col-xl-12 flex">
              <input-number
                class="mr-2"
                :disabled="editar === item.index ? false : true"
                ref="valorAvaliacao"
                v-model="item.item.valorAvaliacao"
                :min="0"
                :max="10"
                :maxLength="10"
              />
              <b-input-group-append
                class="d-flex justify-content-center align-items-center mb-2"
              >
                <b-button
                  class="margin-top"
                  variant="primary"
                  @click="habilitarEdicao(item.index)"
                  >✎</b-button
                >
              </b-input-group-append>
            </div>
          </b-input-group>
        </div>
      </template>
    </template>

    <template #cell(valorRecuperacao)="item">
      <template>
        <div>
          <b-input-group class="mt-3">
            <div class="col-xl-12 flex">
              <input-number
                class="mr-2"
                :disabled="editarValorRecuperacao === item.index ? false : true"
                ref="valorRecuperacao"
                v-model="item.item.valorRecuperacao"
                :min="0"
                :max="10"
                :maxLength="10"
              />
              <b-input-group-append
                class="d-flex justify-content-center align-items-center mb-2"
              >
                <b-button
                  variant="primary"
                  @click="habilitarValorRecuperacao(item.index)"
                  class="margin-top"
                  >✎</b-button
                >
              </b-input-group-append>
            </div>
          </b-input-group>
        </div>
      </template>
    </template>

    <template #cell(observacao)="item">
      <b-input-group class="mt-3">
        <div class="col-xl-12 flex">
          <input-text
            class="mr-2"
            ref="observacao"
            v-model="item.item.observacao"
            :disabled="editarValorObservacao !== item.index ? true : false"
          />
          <b-input-group-append
            class="d-flex justify-content-center align-items-center mb-2"
          >
            <b-button variant="primary" @click="habilitarObservacao(item.index)"
              >✎</b-button
            >
          </b-input-group-append>
        </div>
      </b-input-group>
    </template>
  </b-table>
</template>
<script>
import { InputNumber, InputText } from '@/components/inputs';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  components: {
    InputNumber,
    InputText,
  },
  watch: {
    items: {
      handler() {
        this.$emit('atualizar-notas', this.items);
      },
      deep: true,
    },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'nomeAluno',
            label: 'Aluno',
            sortable: true,
          },
          {
            key: 'valorAvaliacao',
            label: 'Nota',
            sortable: true,
          },
          {
            key: 'valorRecuperacao',
            label: 'Recuperação',
            sortable: true,
          },
          {
            key: 'observacao',
            label: 'Observações',
            sortable: true,
          },
        ],
      },
      editar: '',
      editarValorRecuperacao: '',
      editarValorObservacao: '',
    };
  },
  methods: {
    habilitarEdicao(index) {
      this.editar = index;
    },
    habilitarValorRecuperacao(index) {
      this.editarValorRecuperacao = index;
    },
    habilitarObservacao(index) {
      this.editarValorObservacao = index;
    },
  },
};
</script>

<style>
.flex {
  display: flex;
}
.flex-text-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
}
</style>
